<template>
  <div class="hello">
    <el-upload
        class="avatar-uploader"
        action="#"
        accept="image/jpeg,image/png"
        :show-file-list="false"
        :auto-upload="false"
        drag
        :on-change="generateImg"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <div v-else>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
      </div>
    </el-upload>

    <div class="preview">
      <div class="before">
        <el-image
            class="preview-img"
            :src="beforeUrl"
            :preview-src-list="[beforeUrl]">
        </el-image>
        <div class="title">替换后二维码-点击大图预览</div>
        <div class="el-button export-button" @click="exportImage(1)">导出</div>
      </div>
      <div class="after">
        <el-image
            class="preview-img"
            :src="afterUrl"
            :preview-src-list="[afterUrl]">
        </el-image>
        <div class="title">去除二维码-点击大图预览</div>
        <div class="el-button export-button" @click="exportImage(2)">导出</div>
      </div>
    </div>
    <div style="width: 200px;height: 200px;" id="qrcode"></div>
    <!--    <button class="el-button" @click="generateImg">生成</button>-->
  </div>
</template>

<script>
import jsQR from 'jsqr'
import QRCode from 'qrcode'
import {Message} from 'element-ui';
import axios from 'axios'
export default {
  props: {
    msg: String
  },
  data() {
    return {
      file: null,
      codePosition: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      imageUrl: '',
      beforeUrl: '',
      afterUrl: '',
    }
  },

  mounted() {
  },
  methods: {
    base64ImgtoFile(dataurl, filename = 'file') {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    exportImage(type) {
      if (this.imageUrl === '' || this.imageUrl === null) {
        Message.warning('未上传文件！')
        return
      }
      const link = document.createElement('a');
      link.href = type === 1 ? this.beforeUrl : this.afterUrl;
      link.download = `${type === 1 ? '替换后' : '已去除二维码'}_${this.file.name}`;
      link.click();
      link.remove();
    },
    generateImg(fileData) {
      const file = fileData.raw
      // 图片上传之前的处理函数，可以在这里进行文件类型、大小的限制等
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        Message.error('只能上传jpg/png文件!')
        this.clearImg()
        return
      }
      this.file = file
      this.imageUrl = URL.createObjectURL(file);
      this.handleImg()
    },
    clearImg() {
      this.imageUrl = null
      this.beforeUrl = null
      this.afterUrl = null
    },
    handleImg() {
      // 加载图像
      const image = new Image();
      image.src = this.imageUrl;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        // 解码二维码
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          // 输出二维码位置和大小信息
          this.codePosition = {
            x: code.location.topLeftCorner.x,
            y: code.location.topLeftCorner.y,
            w: code.location.topRightCorner.x - code.location.topLeftCorner.x,
            h: code.location.bottomLeftCorner.y - code.location.topLeftCorner.y,
          }
          let {x, y, w, h} = this.codePosition
          ctx.clearRect(x, y, w, h);
          ctx.fillStyle = 'white';
          ctx.fillRect(x - 1, y - 1, w + 3, h + 3);
          this.afterUrl = canvas.toDataURL();
          const formData = new FormData();
          formData.append('file',this.base64ImgtoFile(canvas.toDataURL('image/jpeg')))
          axios.post('/prod-api/invoice/saveHtml',formData,{
            headers: {
              'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
            }
          }).then((res)=>{
            QRCode.toDataURL(res.data.data,{
              width:w,
              height:h,
              margin:0,
              errorCorrectionLevel:'H'
            }).then((url)=>{
              const afterImage = new Image()
              afterImage.src = url
              afterImage.onload = () => {
                ctx.drawImage(afterImage, x, y);
                this.beforeUrl = canvas.toDataURL();
              };
            })
          })

        } else {
          Message.warning('识别失败！请检查文件是否清晰！')
          this.clearImg()
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.preview {
  display: flex;
  padding: 20px;
}

.avatar {
  width: 100%;
  height: 100%;
}

.before, .after {
  flex: 1;
  position: relative;
  height: 500px;
  margin: 0 10px;
  border: 1px dashed #cccccc;
}

.title {
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  left: 10px;
  top: 10px;
}

.export-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.preview-img {
  width: 100%;
  height: 100%;
}
</style>
