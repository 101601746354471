import Vue from 'vue'
import App from './App.vue'
import { Upload,Image } from 'element-ui';

Vue.config.productionTip = false

Vue.component(Upload.name, Upload);
Vue.component(Image.name, Image);
new Vue({
  render: h => h(App),
}).$mount('#app')
